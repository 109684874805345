import React, { useState } from "react";
import { Button, Drawer, Form, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FormSelectField } from "../../../../core/CustomFormFields";
import OCRPreviewDrawer from "./OCRPreview/OCRPreviewDrawer";
import { Field, Formik } from "formik";
import { createOCRToPdf } from "../../../../../services/admin-ai-viewer";
import { languagesData } from "./LanguagesData";
import * as Yup from "yup";
import "./OCRSelectDrawer.css";

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  languages: Yup.array()
    .min(1, "At least one language must be selected") // Minimum one language is required
    .required("Please select a language"),
});

const OCRSelectDrawer = ({
  isOpenOCR,
  setIsOpenOCR,
  tokenId,
  activeButton,
  setPdfUrl,
  setButtonList,
  setOriginalUrl,
  setPdfContainsText,
}) => {
  const [convertOCRLoading, setConvertOCRLoading] = useState(false);
  const [isOpenOCRPreview, setIsOpenOCRPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");

  const initialValues = {
    languages: ["eng"],
  };

  const titleName =
    activeButton === "Company Registration"
      ? "poi"
      : activeButton === "UBO Certificate" && "ubo";

  const handleClose = () => {
    setIsOpenOCR(false);
  };

  const handleFormSubmit = (values, { resetForm }) => {
    if (!tokenId) {
      return message.error("Token id missing");
    }

    setConvertOCRLoading(true);
    createOCRToPdf({
      token_id: tokenId,
      ocrLanguages: values.languages,
      ocr: titleName,
    })
      .then((res) => {
        if (res?.error) {
          setConvertOCRLoading(false);
          return message.error(res?.error?.message);
        }
        setPreviewUrl(res?.gridData?.outputPath);
        setConvertOCRLoading(false);
        setIsOpenOCRPreview(true);
      })
      .catch((error) => {
        setConvertOCRLoading(false);
        console.log(error);
      });
  };
  return (
    <>
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema} // Attach the validation schema
          onSubmit={handleFormSubmit}
        >
          {({ handleSubmit, setFieldValue, values, errors, touched }) => (
            <Drawer
              placement="right"
              className="OCRSelectDrawer select-none"
              closable={false}
              onClose={handleClose}
              open={isOpenOCR}
              title={
                <div className="flex justify-between items-center">
                  <p className="text-lg font-bold">
                    <span>Perform OCR on</span>
                    <span className="capitalize ml-1">
                      {titleName === "poi"
                        ? "Company Document"
                        : titleName === "ubo" && "UBO Certificate"}
                    </span>
                  </p>
                  <CloseOutlined
                    className="!text-lg"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </div>
              }
              footer={
                <div className="flex justify-end items-center gap-3">
                  <Button type="primary" ghost onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    loading={convertOCRLoading}
                  >
                    Start
                  </Button>
                </div>
              }
            >
              <div className="bg-[#fff] w-full rounded-xl shadow-md sm:p-5 p-4 flex gap-4 flex-col">
                <div className="flex flex-col gap-5">
                  <div>
                    <p className="font-bold mb-2 text-lg capitalize">
                      Basic Guidelines
                    </p>
                    <div className="text-sm font-normal">
                      <div className="flex flex-col gap-2">
                        <div>
                          <span>Convert document with OCR</span> (Optical
                          Character Recognition)
                        </div>
                        <ol>
                          <li>Select the language of the source document.</li>
                          <li>Click "Start"</li>
                          <li>Click "Save"</li>
                          <li>The document is now searchable.</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <p className="font-bold mb-2 text-lg capitalize">
                      Select Languages
                    </p> */}
                    <div className="flex flex-col">
                      <div className="flex justify-between items-center gap-2 mb-2">
                        <p className="text-[#000] font-extrabold text-sm flex gap-1">
                          <span className="text-red-500">*</span>Document
                          languages
                        </p>

                        <p className="text-[#000] font-semibold text-sm">
                          <span>{values.languages.length}</span>/<span>3</span>
                        </p>
                      </div>

                      <Form onFinish={handleSubmit}>
                        <Field name="languages">
                          {({ field }) => {
                            // Create a list of disabled options based on the number of selected languages
                            const disabledOptions =
                              values.languages.length >= 3
                                ? languagesData.map((lang) => ({
                                    ...lang,
                                    disabled: !values.languages.includes(
                                      lang.value
                                    ), // Disable options that are not selected
                                  }))
                                : languagesData;

                            return (
                              <div>
                                <FormSelectField
                                  mode="multiple"
                                  {...field}
                                  value={field.value}
                                  onChange={(selected) => {
                                    if (selected.length <= 3) {
                                      setFieldValue("languages", selected);
                                    }
                                  }}
                                  options={disabledOptions}
                                  placeholder="Select Languages"
                                />
                                {touched.languages && errors.languages ? (
                                  <div className="text-red-500 text-xs font-bold mt-1 ml-1">
                                    {errors.languages}
                                  </div>
                                ) : null}
                              </div>
                            );
                          }}
                        </Field>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>
          )}
        </Formik>
      </>

      {isOpenOCRPreview && (
        <OCRPreviewDrawer
          isOpenOCRPreview={isOpenOCRPreview}
          setIsOpenOCRPreview={setIsOpenOCRPreview}
          handleCloseSelectDrawer={handleClose}
          previewUrl={previewUrl}
          tokenId={tokenId}
          activeButton={activeButton}
          setPdfUrl={setPdfUrl}
          setButtonList={setButtonList}
          setOriginalUrl={setOriginalUrl}
          setPdfContainsText={setPdfContainsText}
        />
      )}
    </>
  );
};

export default OCRSelectDrawer;
