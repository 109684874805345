import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import CustomTable from "../../../ui/CustomTable/CustomTable";
import {
  Critical,
  Passed,
  Pending,
  Rejected,
} from "../../../TeamMember/CheckBtn";
import CustomAvatar from "../../../ui/Avatar";
import MemberDetailDrawer from "../MemberDetailDrawer";
import { getPendingWorkDetail } from "../../../../services/pendingWork";
import { checkTypes } from "../../Constants/CheckTypes.constant";
import { getAllControl } from "../../../../services/control";
import { checkName } from "../../Constants/CheckName.constant";
import "./pendingWorkDrawer.css";

const PendingWorkDrawer = ({
  pendingWorkDrawerOpen,
  pendingWorkDrawerClose,
  getPendingWorkData,
}) => {
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [memberDetailDrawerOpen, setMemberDetailDrawerOpen] = useState(false);
  const [pendingWorkData, setPendingWorkData] = useState(null);
  const [changeData, setChangeData] = useState(false);
  const [controlData, setControlData] = useState(null);
  const [checkData, setCheckData] = useState([]);
  const [trainingControlData, setTrainingControlData] = useState([]);

  const columns = [
    {
      title: "Team members",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <div className="flex items-center justify-start gap-2 min-w-[200px]">
            <CustomAvatar
              firstName={record?.user?.fname}
              lastName={record?.user?.lname}
            />
            <p className="flex flex-col text-[#000] font-bold leading-none">
              <span className="text-base">
                {record?.user?.fname + " " + record?.user?.lname}
              </span>
              {/* <span className="font-normal text-sm">{record?.user?.email}</span> */}
            </p>
          </div>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text, record) => {
        return (
          <div className="flex items-center justify-center gap-2 min-w-[200px]">
            <p className="flex text-[#000] font-normal text-base leading-none">
              {record?.role?.length > 0
                ? record?.role
                    .map((res) => res?.role)
                    .join(", ")
                    .toString()
                : ""}
            </p>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        const userCheck = record?.userCheck ?? [];

        const dueChecks = userCheck.filter(
          (res) => res?.status === "Due"
        ).length;
        const critical = userCheck.filter(
          (res) => res?.status === "Critical"
        ).length;
        const failing = userCheck.filter(
          (res) => res?.status === "Failing"
        ).length;

        return (
          <div className="flex gap-1 justify-center items-center min-w-[135px] whitespace-nowrap">
            <div className="flex gap-3 justify-center items-center flex-wrap min-w-[140px]">
              {failing > 0 && record?.inScope && <Rejected value={failing} />}
              {dueChecks > 0 && record?.inScope && (
                <Pending value={dueChecks} />
              )}
              {critical > 0 && record?.inScope && <Critical value={critical} />}
            </div>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "checks",
      key: "checks",
      render: (text, record) => {
        return (
          <div className="flex gap-3 justify-center items-center">
            <RightOutlined />
          </div>
        );
      },
    },
  ];

  const memberDetailDrawerClose = () => {
    setMemberDetailDrawerOpen(false);
  };

  useEffect(() => {
    getPendingWork();
  }, []);

  const getPendingWork = async (loader = true) => {
    const type = getPendingWorkData?.checkDetails?.checkType;
    console.log(checkTypes[type]);
    setLoading(loader);
    Promise.all([getAllControl(), getPendingWorkDetail(checkTypes[type])])
      .then((res) => {
        const controlData = res[0]?.data;
        const pendingWork = res[1];
        if (controlData) {
          const teamControl = controlData.find(
            (control) => control?.controlName === "Team"
          );
          setControlData(teamControl);
          const trainingControlData = controlData.find(
            (control) => control?.controlName === "Trainings"
          );
          console.log(trainingControlData);
          setTrainingControlData(trainingControlData);
        }
        if (pendingWork) {
          if (pendingWork?.error) {
            setListData([]);
            setCheckData([]);
            // memberDetailDrawerClose();
          } else {
            pendingWork.map((team) => {
              team.userCheck = [...team?.userCheck].sort(
                (a, b) => a?.checkDetails?.order - b?.checkDetails?.order
              );
              return team;
            });
            const singlePendingWorkData = pendingWorkData;
            let check = null;
            if (singlePendingWorkData) {
              check = pendingWork.find(
                (check) => check?._id === singlePendingWorkData?._id
              );
              if (check) {
                setCheckData(check?.userCheck);
              }
            }
            setListData(pendingWork);
          }
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error: ", err);
        setLoading(false);
      });
  };

  const getList = () => {
    setChangeData(true);
    getPendingWork();
  };

  return (
    <>
      <Drawer
        placement="right"
        title={
          <div className="flex justify-between items-center pt-2 px-5">
            <p className="text-xl font-bold">
              {checkName[getPendingWorkData?.checkDetails?.checkType]}
            </p>
            <CloseOutlined
              className="!text-lg"
              onClick={() => {
                pendingWorkDrawerClose(changeData);
              }}
            />
          </div>
        }
        className="pendingWork"
        closable={false}
        onClose={() => {
          pendingWorkDrawerClose(changeData);
        }}
        open={pendingWorkDrawerOpen}
      >
        <div className="flex flex-col">
          <div className="pagination-black pb-3">
            <CustomTable
              columns={columns}
              loading={loading}
              dataSource={listData}
              onRowClick={(res) => {
                setCheckData(res?.userCheck);
                setMemberDetailDrawerOpen(true);
                setPendingWorkData(res);
              }}
              customClassName="addMemberTable"
            />
          </div>
        </div>
      </Drawer>
      {memberDetailDrawerOpen && (
        <MemberDetailDrawer
          memberDetailDrawerOpen={memberDetailDrawerOpen}
          memberDetailDrawerClose={memberDetailDrawerClose}
          memberDetail={pendingWorkData}
          getTeamMemberList={getList}
          controlData={controlData}
          trainingControlData={trainingControlData}
          checkData={checkData}
          manuallyCheckData={true}
        />
      )}
    </>
  );
};

export default PendingWorkDrawer;
{
  /* <Drawer
        placement="right"
        // className="background-color"
        width={"80%"}
        closable={false}
        onClose={() => {
          pendingWorkDrawerClose(changeData);
        }}
        visible={pendingWorkDrawerOpen}
      >
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center px-2">
            <p className="text-xl font-bold"></p>
            <CloseOutlined
              className="!text-lg"
              onClick={() => {
                pendingWorkDrawerClose(changeData);
              }}
            />
          </div>
          <div className="flex flex-col">
            <b className="text-lg pb-3">
              {checkName[getPendingWorkData?.checkDetails?.checkType]}
            </b>
            <div className="pagination-black py-3">
              <CustomTable
                columns={columns}
                loading={loading}
                dataSource={listData}
                onRowClick={(res) => {
                  const type = getPendingWorkData?.checkDetails?.checkType;
                  if (
                    type !== "tm_read_risk_policy_report_mlro" &&
                    type !== "tm_read_risk_asses_report_mlro" &&
                    type !== "mlro_ensures_tm_training"
                  ) {
                    setCheckData(res?.userCheck);
                    setMemberDetailDrawerOpen(true);
                    setPendingWorkData(res);
                  }
                }}
                customClassName="addMemberTable"
              />
            </div>
          </div>
        </div>
      </Drawer> */
}
