export const languagesData = [
  { value: "afr", label: "Afrikaans" },
  { value: "sqi", label: "Albanian" },
  { value: "amh", label: "Amharic" },
  { value: "ara", label: "Arabic" },
  { value: "hye", label: "Armenian" },
  { value: "asm", label: "Assamese" },
  { value: "aze_cyrl", label: "Azerbaijani - Cyrilic" },
  { value: "aze", label: "Azerbaijani" },
  { value: "eus", label: "Basque" },
  { value: "bel", label: "Belarusian" },
  { value: "ben", label: "Bengali" },
  { value: "bos", label: "Bosnian" },
  { value: "bre", label: "Breton" },
  { value: "bul", label: "Bulgarian" },
  { value: "mya", label: "Burmese" },
  { value: "cat", label: "Catalan" },
  { value: "ceb", label: "Cebuano" },
  { value: "khm", label: "Central Khmer" },
  { value: "chr", label: "Cherokee" },
  { value: "chi_sim", label: "Chinese - Simplified" },
  { value: "chi_tra", label: "Chinese - Traditional" },
  { value: "cos", label: "Corsican" },
  { value: "hrv", label: "Croatian" },
  { value: "ces", label: "Czech" },
  { value: "dan", label: "Danish" },
  { value: "nld", label: "Dutch; Flemish" },
  { value: "dzo", label: "Dzongkha" },
  { value: "enm", label: "English, Middle (1100-1500)" },
  { value: "eng", label: "English" },
  { value: "epo", label: "Esperanto" },
  { value: "est", label: "Estonian" },
  { value: "fao", label: "Faroese" },
  { value: "fil", label: "Filipino (old - Tagalog)" },
  { value: "fin", label: "Finnish" },
  { value: "frm", label: "French, Middle (ca.1400-1600)" },
  { value: "fra", label: "French" },
  { value: "glg", label: "Galician" },
  { value: "kat_old", label: "Georgian - Old" },
  { value: "kat", label: "Georgian" },
  { value: "deu_latf", label: "German (Fraktur Latin)" },
  { value: "deu", label: "German" },
  { value: "grc", label: "Greek, Ancient" },
  { value: "ell", label: "Greek, Modern (1453-)" },
  { value: "guj", label: "Gujarati" },
  { value: "hat", label: "Haitian; Haitian Creole" },
  { value: "heb", label: "Hebrew" },
  { value: "hin", label: "Hindi" },
  { value: "hun", label: "Hungarian" },
  { value: "isl", label: "Icelandic" },
  { value: "ind", label: "Indonesian" },
  { value: "iku", label: "Inuktitut" },
  { value: "gle", label: "Irish" },
  { value: "ita_old", label: "Italian - Old" },
  { value: "ita", label: "Italian" },
  { value: "jpn", label: "Japanese" },
  { value: "jav", label: "Javanese" },
  { value: "kan", label: "Kannada" },
  { value: "kaz", label: "Kazakh" },
  { value: "kir", label: "Kirghiz; Kyrgyz" },
  { value: "kor_vert", label: "Korean (vertical)" },
  { value: "kor", label: "Korean" },
  { value: "kmr", label: "Kurmanji (Kurdish - Latin Script)" },
  { value: "lao", label: "Lao" },
  { value: "lat", label: "Latin" },
  { value: "lav", label: "Latvian" },
  { value: "lit", label: "Lithuanian" },
  { value: "ltz", label: "Luxembourgish" },
  { value: "mkd", label: "Macedonian" },
  { value: "msa", label: "Malay" },
  { value: "mal", label: "Malayalam" },
  { value: "mlt", label: "Maltese" },
  { value: "mri", label: "Maori" },
  { value: "mar", label: "Marathi" },
  { value: "equ", label: "Math / Equation detection" },
  { value: "mon", label: "Mongolian" },
  { value: "nep", label: "Nepali" },
  { value: "nor", label: "Norwegian" },
  { value: "oci", label: "Occitan (post 1500)" },
  { value: "ori", label: "Oriya" },
  { value: "pan", label: "Panjabi; Punjabi" },
  { value: "fas", label: "Persian" },
  { value: "pol", label: "Polish" },
  { value: "por", label: "Portuguese" },
  { value: "pus", label: "Pushto; Pashto" },
  { value: "que", label: "Quechua" },
  { value: "ron", label: "Romanian; Moldavian; Moldovan" },
  { value: "rus", label: "Russian" },
  { value: "san", label: "Sanskrit" },
  { value: "srp", label: "Serbian" },
  { value: "sin", label: "Sinhala; Sinhalese" },
  { value: "slk", label: "Slovak" },
  { value: "slv", label: "Slovenian" },
  { value: "spa_old", label: "Spanish - Old" },
  { value: "spa", label: "Spanish" },
  { value: "sun", label: "Sundanese" },
  { value: "swa", label: "Swahili" },
  { value: "swe", label: "Swedish" },
  { value: "syr", label: "Syriac" },
  { value: "tgl", label: "Tagalog (Filipino)" },
  { value: "tgk", label: "Tajik" },
  { value: "tam", label: "Tamil" },
  { value: "tat", label: "Tatar" },
  { value: "tel", label: "Telugu" },
  { value: "tha", label: "Thai" },
  { value: "bod", label: "Tibetan" },
  { value: "tir", label: "Tigrinya" },
  { value: "ton", label: "Tonga" },
  { value: "tur", label: "Turkish" },
  { value: "uig", label: "Uighur; Uyghur" },
  { value: "ukr", label: "Ukrainian" },
  { value: "urd", label: "Urdu" },
  { value: "uzb_cyrl", label: "Uzbek - Cyrilic" },
  { value: "uzb", label: "Uzbek" },
  { value: "vie", label: "Vietnamese" },
  { value: "cym", label: "Welsh" },
  { value: "fry", label: "Western Frisian" },
  { value: "yid", label: "Yiddish" },
  { value: "yor", label: "Yoruba" },
];

const languagesForTranslate = [
  { value: "sqi", label: "Albanian" },
  { value: "ara", label: "Arabic" },
  { value: "hye", label: "Armenian" },
  { value: "bul", label: "Bulgarian" },
  { value: "chi_sim", label: "Chinese - Simplified" },
  { value: "chi_tra", label: "Chinese - Traditional" },
  { value: "hrv", label: "Croatian" },
  { value: "ces", label: "Czech" },
  { value: "dan", label: "Danish" },
  { value: "nld", label: "Dutch" },
  { value: "eng", label: "English" },
  { value: "est", label: "Estonian" },
  { value: "fin", label: "Finnish" },
  { value: "fra", label: "French" },
  { value: "kat", label: "Georgian" },
  { value: "deu", label: "German" },
  { value: "ell", label: "Greek, Modern (1453-)" },
  { value: "heb", label: "Hebrew" },
  { value: "hin", label: "Hindi" },
  { value: "hun", label: "Hungarian" },
  { value: "isl", label: "Icelandic" },
  { value: "ind", label: "Indonesian" },
  { value: "gle", label: "Irish" },
  { value: "ita", label: "Italian" },
  { value: "jpn", label: "Japanese" },
  { value: "kor", label: "Korean" },
  { value: "lav", label: "Latvian" },
  { value: "lit", label: "Lithuanian" },
  { value: "nor", label: "Norwegian" },
  { value: "fas", label: "Persian" },
  { value: "pol", label: "Polish" },
  { value: "por", label: "Portuguese" },
  { value: "ron", label: "Romanian; Moldavian; Moldovan" },
  { value: "rus", label: "Russian" },
  { value: "slk", label: "Slovak" },
  { value: "slv", label: "Slovenian" },
  { value: "spa", label: "Spanish" },
  { value: "swe", label: "Swedish" },
  { value: "tur", label: "Turkish" },
  { value: "ukr", label: "Ukrainian" },
  { value: "vie", label: "Vietnamese" },
  { value: "yid", label: "Yiddish" },
  { value: "nob", label: "Norwegian Bokmål" },
  { value: "srp", label: "Serbian" },
];

export const languagesDataForTranslate = languagesForTranslate.sort((a, b) =>
  a.label.localeCompare(b.label)
);

export const getLabelByValue = (value) => {
  const language = languagesForTranslate.find((lang) => lang.value === value);
  return language ? language.label : null;
};
